<template>
	<div id="adoptOrderList">
		<page-order-list :is-shop="0"></page-order-list>
	</div>
</template>

<script>
import pageOrderList from '@/components/layout/adopt/page-order-list.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	name:"AdoptOrderList",
	components:{
		pageOrderList
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			let type = to.path == '/adopt/orderDetail' ? 1 : 2
			store.dispatch("setKeepPage",{name:from.name,type})
			next();
		})
	}
}
</script>

<style>

</style>
